import React, { useEffect, useState } from 'react';
import * as propTypes from 'lib/prop_types';

import { MessageTypes as messageTypes, Toast } from '@user-interviews/ui-design-system';
import { flashMessagePropType } from './prop_types';

const DISMISS_COOKIE_VALUE = 'dismissed';
const TEN_YEARS_IN_SECONDS = 10 * 365 * 24 * 60 * 60;

function dismissFlash(cookieKey) {
  document.cookie = `${cookieKey}=${DISMISS_COOKIE_VALUE};max-age=${TEN_YEARS_IN_SECONDS}`;
}

function shouldShowFlash(cookieKey) {
  const cookieMatch = `${cookieKey}=${DISMISS_COOKIE_VALUE}`;
  return !document.cookie.match(new RegExp(cookieMatch));
}

function OneTimeFlash({ cookieKey, message, messageType }) {
  const [flashMessages, setFlashMessages] = useState([]);

  useEffect(
    () => {
      if (shouldShowFlash(cookieKey)) {
        setFlashMessages([{
          id: cookieKey,
          message,
          type: messageType,
        }]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cookieKey],
  );

  function handleFlashClosed() {
    dismissFlash(cookieKey);
    setFlashMessages([]);
  }

  return (
    <Toast
      messages={flashMessages}
      onToastClosed={handleFlashClosed}
    />
  );
}

OneTimeFlash.propTypes = {
  cookieKey: propTypes.string.isRequired,
  message: flashMessagePropType.isRequired,
  messageType: propTypes.oneOf(Object.values(messageTypes)),
};

OneTimeFlash.defaultProps = {
  messageType: messageTypes.INFO,
};

export default OneTimeFlash;
