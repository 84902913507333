import React from 'react';
import { Tooltip } from '@user-interviews/ui-design-system';

import * as propTypes from 'lib/prop_types';

function TooltipWithLink({
  iconClasses, placement, text, theme,
}) {
  return (
    <Tooltip
      iconClasses={iconClasses}
      placement={placement}
      text={(
        <span
        /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: text }}
        />

    )}
      theme={theme}
    />
  )
}

TooltipWithLink.defaultProps = {
  iconClasses: undefined,
  placement: undefined,
  theme: undefined,
};

TooltipWithLink.propTypes = {
  iconClasses: propTypes.string,
  placement: propTypes.string,
  text: propTypes.string.isRequired,
  theme: propTypes.string,
};

export default TooltipWithLink;
