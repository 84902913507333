import * as propTypes from 'lib/prop_types';

export const flashMessagePropType = propTypes.shape({
  id: propTypes.string,
  message: propTypes.oneOfType([
    propTypes.string,
    propTypes.node,
    propTypes.shape({
      body: propTypes.string,
      title: propTypes.string,
    }),
  ]),
  type: propTypes.string,
});
