import Modal from 'react-modal';
import { isClient } from 'lib/ssr_utils';

Modal.defaultProps.closeTimeoutMS = 200;
Modal.defaultStyles.content = {};
Modal.defaultStyles.overlay.backgroundColor = undefined;

// Our application scripts currently appear before, rather than after, DOM
// content. For various reasons, this isn’t easy to change, so some special
// boilerplate is needed here instead of just writing:
//
//   Modal.setAppElement('#ui-window-root');
//
// We need to set it after the point where the app root has been attached to the
// document. But domcontentloaded would not work, because react apps which may
// include Modal may begin mounting before that occurs. See the base erb file
// for where the ui-windowrootappended event gets dispatched.

const setAppElement = Modal.setAppElement.bind(Modal, '#ui-window-root');

if (isClient) {
  if (document.getElementById('ui-window-root')) {
    setAppElement();
  } else {
    document.addEventListener('ui-windowrootappended', setAppElement);
  }
}
