import React from 'react';
import { useToast, Toast } from '@user-interviews/ui-design-system';
import * as propTypes from 'lib/prop_types';

import { flashMessagePropType } from './prop_types';

function FlashRenderer(props) {
  const { messages, dismissMessage } = useToast([...props.messages]);

  const mappedMessages = messages.map((message) => {
    if (typeof message.message === 'string') {
      return message;
    }

    const { message: messageObj, ...rest } = message;

    return {
      ...rest,
      ...messageObj,
    };
  });

  return (
    <Toast
      autoDismiss={props.autoDismiss}
      header={props.header}
      messages={mappedMessages}
      onToastClosed={dismissMessage}
    />
  );
}

FlashRenderer.propTypes = {
  autoDismiss: propTypes.bool,
  header: propTypes.bool.isRequired,
  messages: propTypes.arrayOf(flashMessagePropType).isRequired,
};

FlashRenderer.defaultProps = {
  autoDismiss: false,
};

export default FlashRenderer;
